import "./Loading.css";

const Loading = () => {

        return (
            <div>
                <p className="blink loadingText">Generating URL...</p>
            </div>
        )
}

export default Loading;